.bg-color {
  background-color: rgba(245, 245, 245, 0.51);
}

.padding-left {
  padding-left: 120px !important;
}

.padding-left1 {
  padding-left: 30px !important;
}
.padding-right1 {
  padding-right: 30px !important;
}

.padding-right {
  padding-right: 120px !important;
}
.padding-bottom {
  padding-bottom: 100px !important;
}

.button-overview {
  display: inline-block;
  padding: 10px 20px;
  background-color: white !important;
  color: black;
  border: 1px solid rgba(223, 223, 223, 1);
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.button-overview1 {
  display: inline-block;
  padding: 10px 20px;
  background-color: white !important;
  color: black;
  border: 1px solid rgba(223, 223, 223, 1);
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  margin-bottom: 10px;
}

.margin-left {
  margin-left: 250px !important;
}

.font-size {
  font-size: 12px !important;
}

.text-pink {
  color: rgba(198, 8, 110, 1);
}

.text-gray {
  color: rgba(110, 119, 134, 1);
}

.exp_footer_page {
  background-color: white;
  color: black !important;
}
.exp_footer_page ul {
  list-style-type: none;
}
.exp_footer_page ul li {
  margin-bottom: 10px;
  font-size: 14px;
}
.exp_footer_page ul p {
  text-transform: uppercase;
}
.exp_footer_copyright {
  color: black;
  background-color: #f5f5f582;
}
.exp_footer_copyright p {
  font-size: 12px;
}
.footer-description {
  max-width: 300px;
  line-height: 1.5;
}
