/* ----- Footer ----- */

.efooter_page {
  background-color: #ffffff;
  color: black;
}
.efooter_page ul {
  list-style-type: none;
}
.efooter_page ul li {
  margin-bottom: 10px;
  font-size: 14px;
}
.efooter_page ul p {
  text-transform: uppercase;
}
.efooter_copyright {
  color: #000000;
  background-color: #e9e9e9;
}
.efooter_copyright p {
  font-size: 12px;
}

.common_pe {
  font-size: 10px;
  width: 300px;
  height: 50px;
  margin-top: 25px;
}

.card {
  display: flex;
  margin: 10px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.text-pink {
  color: rgba(198, 8, 110, 1);
}

.border_grey {
  border: 1px solid rgba(223, 223, 223, 1);
}

.font-size-banner-entries {
  font-size: 14px;
}

.font-size-search {
  font-size: 16px;
}

.pink {
  color: #c6086e;
}

.half-bg {
  background: linear-gradient(
    to bottom,
    rgba(31, 40, 78, 1) 60%,
    rgba(245, 245, 245, 0.51) 40%
  );
}

.background-color {
  background-color: rgba(245, 245, 245, 0.51);
}

.blue {
  background-color: rgba(31, 40, 78, 1);
}

.padding-x {
  padding-left: 120px;
  padding-right: 120px;
}

.search-container {
  display: flex;
  align-items: center;
  box-shadow: #ddd;

  padding: 5px;
  max-width: 600px;
}

.search-input {
  border: none;
  outline: none;
  flex-grow: 1;
  padding: 5px;
}

.search-button {
  background-color: rgba(198, 8, 110, 1) !important;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.icon {
  fill: #fff;
}

.blue {
  position: relative;
}

.circle-container {
  position: absolute;
  top: 20px;
  right: 400px;
  overflow: hidden;
}

.circle-container img {
  width: 200px;
  height: auto;
}
