.circle-container {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center exactly */
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
}

.circle-container img {
  width: 100%;
  height: auto;
}

