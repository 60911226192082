/* ----- Header ----- */

.header_page .nav-link {
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 500;
}
.header_page .nav-link:hover {
  color: #ffffff;
}
.header_page.scrolled {
  background-color: #f5f5f582;
}
.header_page.scrolled .nav-link {
  color: var(--theme-secondary-color);
  font-weight: 500;
}

/* ----- Banner ----- */

.banner_page {
  width: 100%;
  overflow: hidden;
  background: url("../../Assets/Images/banner_bg.png");
  background-color: #ffffff;
}
.banner_page .banner_left_content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner_page .banner_text {
  width: 70%;
}

/* ----- Counts ----- */

.counts_page {
  text-transform: uppercase;
  background-color: var(--theme-primary-background-color);
  padding: 25px;
}

.counts_page .counts_content .counts_text_content {
  padding: 0px 20px;
  border-right: 1px solid var(--theme-secondary-background-color);
}

.counts_page .counts_content :last-child {
  border-right: none;
}

.counts_page .main_text {
  font-size: 80px;
  margin-bottom: 0px;
  /* font-weight: 600; */
}

.main_text {
  font-size: 35px;
  margin-bottom: 0px;
  /* font-weight: 600; */
}
.counts_page .sub_text {
  font-size: 12px;
  margin-bottom: 0px;
  font-weight: 500;
}

/* ----- Innovation ----- */

.innovation_page {
  background: #ffffff;
  background-image: url("../../Assets/Images/innovation_bg.png");
}
.innovation_page .right_side_content {
  display: flex;
  align-items: center;
}
.innovation_page .innovation_bottom_content {
  border-radius: 8px;
  margin-top: 40px;
  background-color: var(--theme-primary-background-color);
  padding: 20px;
}
.innovation_page .innovation_bottom_content .hash {
  font-weight: 600;
  color: var(--theme-secondary-background-color);
  font-size: 40px;
}
.innovation_page .innovation_bottom_content .inn_icon {
  height: 50px;
  width: 60px;
}

/* ----- Easy To Start ----- */

.easy_to_start_page {
  background: url("../../Assets/Images/easy_to_start_bg.png");
}
.content_text {
  text-align: center;
  color: #ffffff;
}
.easy_to_start_page .slider_card {
  background-color: #ffffff;
}

.easy_to_start_page .slick-initialized .slick-slide {
  padding: 10px;
}
.easy_to_start_page .slider_card {
  padding: 20px;
  border-radius: 8px;
  min-height: 420px;
}
.easy_to_start_page .slider_card .card_heading {
  font-weight: 600;
}
.easy_to_start_page .slick-next:before {
  font-size: 15px;
  line-height: 1;
  opacity: 0.75;
  color: transparent;
  border: solid rgb(255, 255, 255);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg) !important;
  -webkit-transform: rotate(-45deg);
}
.easy_to_start_page .slick-prev:before {
  font-size: 15px;
  line-height: 1;
  opacity: 0.75;
  color: transparent;
  border: solid rgb(255, 255, 255);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg) !important;
  -webkit-transform: rotate(135deg);
}

/* ----- Technology ----- */

.technology_page {
  background-color: var(--theme-primary-background-color);
}
.technology_page .tech_icon {
  height: 50px;
  width: 60px;
}
.technology_page .sol_icon_div {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
}
.technology_page .sol_icon_div p {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 10px;
  margin-bottom: 0px;
}
.technology_page .sol_icon_div img {
  height: 50px;
  width: 50px;
  margin-bottom: 20px;
}

/* ----- Blog ----- */

.blog_page {
  background: url("../../Assets/Images//blog_bg.png");
  text-align: center;
}
.blog_page .blog_heading {
  color: #ffffff;
}
.blog_page .blog_card {
  position: relative;
  display: flex;
  justify-content: center;
}
.blog_page .blog_sub_card {
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  position: absolute;
  bottom: -80%;
  margin: 55px 30px;
}
.blog_page .blog_sub_card h6 {
  background-color: var(--theme-primary-color);
  padding: 10px 0px;
  font-weight: 600;
  margin: 0;
  color: #ffffff;
}
.blog_page .blog_sub_card .bottom_content {
  padding: 20px;
}
.p_blog_text {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* ----- Contact ----- */

.contact_page {
  margin-top: 200px;
  background-color: #ffffff;
}
.contact_page .free_consulting {
  padding: 25px;
  color: #ffffff;
  border-radius: 12px;
  border-top: 4px solid var(--theme-primary-color);
  background-color: #040d18;
  text-align: center;
}
.contact_page .free_consulting h3 {
  text-transform: uppercase;
}
.contact_page .form-control {
  background: transparent;
  padding: 10px;
  border: 1px solid #1e395a;
  background: #1e395a54;
}
.contact_page .form-control::placeholder {
  color: #ffffff;
  font-family: "Play", sans-serif;
}

/* ----- Footer ----- */

.footer_page {
  /* background: var(--theme-secondary-color); */
  background-color: #040d18;
  color: #ffffff;
}
.footer_page ul {
  list-style-type: none;
}
.footer_page ul li {
  margin-bottom: 10px;
  font-size: 14px;
}
.footer_page ul p {
  text-transform: uppercase;
}
.footer_copyright {
  color: #ffffff;
  background-color: #000000;
  /* background-color: #0e1f35; */
}
.footer_copyright p {
  font-size: 12px;
}

/* Small devices such as large phones (640px and up) */

@media only screen and (max-width: 767px) {
  .banner_page .banner_left_content {
    height: 450px;
  }
  .counts_page .counts_content .counts_text_content {
    padding: 10px 20px;
    border-right: 0;
  }
  .common_container {
    margin: auto;
    max-width: 100%;
  }
  .common_btn2 {
    font-size: 13px;
    padding: 7px;
  }
  .common_btn {
    padding: 8px 8px !important;
    font-size: 14px;
  }
  .social_icons.ms-5.d-flex.gap-4 {
    gap: 15px !important;
  }
  .contact_page .free_consulting {
    margin-top: 30px;
  }
  .common_padding {
    padding: 20px;
  }
  nav.header_page.navbar.navbar-expand-lg.navbar-light.fixed-top span {
    font-size: 15px;
  }
  div#responsive-navbar-nav {
    background-color: #000000d4;
    padding: 10px;
  }
}

@media only screen and (max-width: 640px) {
  .banner_page .banner_left_content {
    height: 450px;
  }
  .common_btn {
    padding: 8px 8px !important;
    font-size: 14px;
  }
}

/* Medium devices such as tablets (768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices such as laptops (1024px and up) */
@media only screen and (min-width: 1024px) {
}

/* Largest devices such as desktops (1280px and up) */
@media only screen and (min-width: 1280px) {
}

.bg-color {
  background-color: #f5f5f582;
}
