@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap");

body {
  font-family: "Poppins", sans-serif;
  color: var(--theme-secondary-color);
  background-color: var(--theme-primary-background-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Play", sans-serif;
}
.headig_text {
  font-weight: 600;
}
.highlighted_text {
  color: var(--theme-primary-color);
}
.common_btn {
  background-color: var(--theme-primary-color) !important;
  color: #ffffff !important;
  border-radius: 5px !important;
  padding: 8px 15px !important;
  text-transform: uppercase;
}
.common_btn2:hover,
.common_btn:hover {
  background-color: var(--theme-secondary-color) !important;
  color: #ffffff !important;
}
.common_btn2 {
  border: 1px solid var(--theme-secondary-color) !important;
  box-shadow: 0px 0px 20px 0px #00000026 !important;
  padding: 8px 15px !important;
  border-radius: 5px !important;
  text-transform: uppercase;
}
.common_container {
  margin: auto;
  max-width: 70%;
}

.common_container1 {
  margin: auto;
  max-width: 80%;
}

.logos_container {
  margin: auto;
  max-width: 87%;
}

.common_padding {
  padding: 40px;
  padding-bottom: 40px;
}
.common_p {
  font-size: 14px;
}
.p_span {
  font-weight: 700;
}

.white_content {
  color: #ffffff;
}
.exp_layout {
  background-color: var(--theme-primary-background-color);
}
